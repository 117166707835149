import React from "react";
import { Box, Flex, Image, Text, Grid } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { motion } from "framer-motion";

import cuteCat from "../assets/images/testimonial1.webp";
import cuteCat2 from "../assets/images/testimonial2.webp";
import cuteDog from "../assets/images/testimonial3.webp";

const Testimonials = () => {

  const MotionBox = motion(Box);

  const testimonials = [
    {
      id: 1,
      name: "Sarah Lee",
      feedback:
        "AirPetTaxi provided an exceptional experience for both me and my cat, Whiskers. Their attention to detail and the comfort they provided during the journey was beyond what I expected. It's clear they truly care about pets!",
      imageUrl: cuteCat,
    },
    {
      id: 2,
      name: "Michelle Brown",
      feedback:
        "I was really anxious about flying my dog, Max, for the first time, but AirPetTaxi made the process stress-free. Their professional and caring approach reassured me that Max was in good hands. Thank you for a fantastic service!",
      imageUrl: cuteDog,
    },
    {
      id: 3,
      name: "Emily Johnson",
      feedback:
        "Our experience with AirPetTaxi was top-notch from start to finish. They kept us informed throughout the journey, and our cat arrived happy and well-cared-for. Their dedication to pet comfort is truly commendable.",
      imageUrl: cuteCat2,
    },
    // {
    //     id: 4,
    //     name: 'David Nguyen',
    //     feedback:
    //         'I’m impressed with how seamless the entire process was with AirPetTaxi. They took care of all the details, making our cat’s journey comfortable and safe. Their professionalism and love for animals really stand out.',
    //     imageUrl: cuteCat3,
    // },
    // Add more testimonials as needed
  ];

  return (
    <Box paddingY="8" minH="100vh">
      {/*<Container>*/}
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr" }}
        // gap={{base: '0', md: '2'}}
        gap={0}
        marginTop="8"
        display={{ base: "none", md: "grid" }}
      >
        {testimonials.map((testimonial) => (
          <MotionBox
            transition={{ duration: 0.8, delay: 0.2 }}
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            key={testimonial.id}
            position="relative"
            overflow="hidden"
            borderRadius="lg"
            boxShadow="md"
            width="100%"
          >
            <Image
              src={testimonial.imageUrl}
              alt={testimonial.name}
              width={"100%"}
              loading="lazy"
              maxHeight="350px"
              objectFit="cover"
              borderTopRadius="lg"
            />
            <Flex
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              bg="secondary.500"
              padding="2"
              height="100%"
              width="100%"
              opacity="0"
              textAlign="center"
              transition="opacity 0.5s"
              _hover={{ opacity: "0.75" }}
              alignItems="center"
            >
              <Box>
                <Text fontSize="md" color="white">
                  {testimonial.feedback}
                </Text>
                <Text
                  fontSize="lg"
                  color="white"
                  fontWeight="bold"
                  marginTop="2"
                >
                  - {testimonial.name}
                </Text>
              </Box>
            </Flex>
          </MotionBox>
        ))}
      </Grid>

      {/* For mobile devices, display testimonials in a carousel */}
      <Box
        display={{ base: "block", md: "none" }}
        maxWidth="350px"
        margin="0 auto"
      >
        <Carousel infiniteLoop={true} showThumbs={false}>
          {testimonials.map((testimonial) => (
            <Box key={testimonial.id}>
              <Image
                src={testimonial.imageUrl}
                alt={testimonial.name}
                maxH="200px"
                width="100%"
                objectFit="cover"
                borderTopRadius="lg"
              />
              <Box
                bg="secondary.400"
                padding="5"
                paddingY="3"
                borderBottomRadius="lg"
              >
                <Text fontSize="lg" color="white">
                  {testimonial.feedback}
                </Text>
                <Text fontSize="xl" color="white" fontWeight="bold" marginY="3">
                  - {testimonial.name}
                </Text>
              </Box>
            </Box>
          ))}
        </Carousel>
      </Box>
      {/*</Container>*/}
    </Box>
  );
};

export default Testimonials;
