const airports = [
  {
    name: "Hartsfield–Jackson Atlanta International Airport",
    city: "Atlanta",
    state: "Georgia",
  },
  {
    name: "Los Angeles International Airport",
    city: "Los Angeles",
    state: "California",
  },
  {
    name: "Chicago O'Hare International Airport",
    city: "Chicago",
    state: "Illinois",
  },
  {
    name: "Dallas/Fort Worth International Airport",
    city: "Dallas/Fort Worth",
    state: "Texas",
  },
  {
    name: "Denver International Airport",
    city: "Denver",
    state: "Colorado",
  },
  {
    name: "John F. Kennedy International Airport",
    city: "New York",
    state: "New York",
  },
  {
    name: "San Francisco International Airport",
    city: "San Francisco",
    state: "California",
  },
  {
    name: "Seattle–Tacoma International Airport",
    city: "Seattle",
    state: "Washington",
  },
  {
    name: "McCarran International Airport",
    city: "Las Vegas",
    state: "Nevada",
  },
  {
    name: "Orlando International Airport",
    city: "Orlando",
    state: "Florida",
  },
  {
    name: "Miami International Airport",
    city: "Miami",
    state: "Florida",
  },
  {
    name: "George Bush Intercontinental Airport",
    city: "Houston",
    state: "Texas",
  },
  {
    name: "Phoenix Sky Harbor International Airport",
    city: "Phoenix",
    state: "Arizona",
  },
  {
    name: "Charlotte Douglas International Airport",
    city: "Charlotte",
    state: "North Carolina",
  },
  {
    name: "Newark Liberty International Airport",
    city: "Newark",
    state: "New Jersey",
  },
  {
    name: "Boston Logan International Airport",
    city: "Boston",
    state: "Massachusetts",
  },
  {
    name: "Minneapolis–Saint Paul International Airport",
    city: "Minneapolis",
    state: "Minnesota",
  },
  {
    name: "Detroit Metropolitan Wayne County Airport",
    city: "Detroit",
    state: "Michigan",
  },
  {
    name: "Philadelphia International Airport",
    city: "Philadelphia",
    state: "Pennsylvania",
  },
  {
    name: "LaGuardia Airport",
    city: "New York",
    state: "New York",
  },
  {
    name: "Fort Lauderdale–Hollywood International Airport",
    city: "Fort Lauderdale",
    state: "Florida",
  },
  {
    name: "Baltimore/Washington International Thurgood Marshall Airport",
    city: "Baltimore",
    state: "Maryland",
  },
  {
    name: "Salt Lake City International Airport",
    city: "Salt Lake City",
    state: "Utah",
  },
  {
    name: "Ronald Reagan Washington National Airport",
    city: "Arlington",
    state: "Virginia",
  },
  {
    name: "San Diego International Airport",
    city: "San Diego",
    state: "California",
  },
  {
    name: "Dulles International Airport",
    city: "Dulles",
    state: "Virginia",
  },
  {
    name: "Norman Y. Mineta San Jose International Airport",
    city: "San Jose",
    state: "California",
  },
  {
    name: "Portland International Airport",
    city: "Portland",
    state: "Oregon",
  },
  {
    name: "Honolulu International Airport",
    city: "Honolulu",
    state: "Hawaii",
  },
  {
    name: "Kansas City International Airport",
    city: "Kansas City",
    state: "Missouri",
  },
];
export default airports;
