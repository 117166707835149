import React, { useState, useMemo } from "react";
import {
  Box,
  VStack,
  Button,
  Container,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select as ChakraSelect,
  useTheme,
  FormErrorMessage,
  Textarea,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import Select from "react-select";
import { Helmet } from "react-helmet";

import {
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";


import { motion } from "framer-motion";
import { CSSTransition } from "react-transition-group";

import { useForm } from "react-hook-form";

import citiesData from "../cities";

import CustomAlert from "./CustomAlert";

const MotionHeading = motion(Heading);
const MotionButton = motion(Button);

const api_link = "https://api.airpettaxi.com/get-quote/";

// This value is from the props in the UI
const paypal_style = { "layout": "vertical" };

const GetAQuote = () => {
  const theme = useTheme();

  // const [selectedCity, setSelectedCity] = useState(null);

  const formBorderStyle = {
    borderColor: theme.colors.secondary[500],
  };

  const cityOptions = useMemo(() => {
    let options = [];
    Object.keys(citiesData).forEach((state) => {
      citiesData[state].forEach((city) => {
        options.push({
          name: "departure_city",
          label: `${city}, ${state}`,
          value: `${city}, ${state}`,
        });
      });
    });
    return options;
  }, []);

  const initialFormData = {
    full_name: "",
    email: "",
    contact_number: "",
    pet_type: "cat",
    pet_breed: "",
    pet_weight: "",
    departure_city: "",
    arrival_city: "",
    departure_date: "",
    additional_info: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: "", body: "" });

  const showAlert = (title, body) => {
    setAlertContent({ title, body });
    setAlertOpen(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDepartureCityChange = (option) => {
    setFormData({ ...formData, departure_city: option.value });
    // Additional logic when a city is selected
  };
  const handleArrivalCityChange = (option) => {
    setFormData({ ...formData, arrival_city: option.value });
    // Additional logic when a city is selected
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(api_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Reset form
        setFormData(initialFormData);
        showAlert("Success!", "Your form submitted successfully!");
      } else {
        showAlert(
          "Error",
          "There was an issue with the server, Please try again"
        );
      }
    } catch (error) {
      console.error("Error:", error);
      showAlert("Error", "There was an issue submitting the form");
    }
  };

  const [submitted, setSubmitted] = useState(false);

  function createOrder(data, actions) {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: "10.0", // Replace with the amount you want to charge
        },
      }],
    });
  }


  function onApprove(data) {

    console.log("Payment approved", data);

    // replace this url with your server
    // return fetch("https://react-paypal-js-storybook.fly.dev/api/paypal/capture-order", {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //         orderID: data.orderID,
    //     }),
    // })
    //     .then((response) => response.json())
    //     .then((orderData) => {
    //         // Your code here after capture the order 
    //     });
  }

  // Custom component to wrap the PayPalButtons and show loading spinner
  const ButtonWrapper = ({ showSpinner }) => {
    const [{ isPending }] = usePayPalScriptReducer();

    return (
      <>
        {(showSpinner && isPending) && <div className="spinner" />}
        <PayPalButtons
          style={paypal_style}
          disabled={false}
          forceReRender={[paypal_style]}
          fundingSource={undefined}
          // createOrder={createOrder}
          onApprove={onApprove}
        />
      </>
    );
  }


  return (
    <CSSTransition classNames="page" timeout={500}>
      <div className="page-entered">
        <Helmet>
          <title>Get a Quote - AirPetTaxi</title>
          <meta
            name="description"
            content="Request a personalized quote for your pet's travel with AirPetTaxi. Our form makes it easy to provide details about your pet and travel plans, so we can give you an accurate estimate."
          />
          <meta
            name="keywords"
            content="pet travel quote, pet taxi estimate, AirPetTaxi rates, pet travel cost, pet transportation pricing"
          />
          {/* Additional relevant SEO tags */}

          <script>
            {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                  n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '2899328293629083');
              fbq('track', 'PageView'); 
            `}
          </script>
          <noscript>
            {`
            <img height="1" width="1" style="display:none"
              src="https://www.facebook.com/tr?id=2899328293629083&ev=PageView&noscript=1" />
            `}
          </noscript>
        </Helmet>
        <Box
          overflowX="hidden"
          as="section"
          bg={theme.colors.background}
          // color="text"
          p={8}
          borderRadius="md"
          boxShadow="lg"
        >
          {/* Your form JSX */}
          <CustomAlert
            isOpen={isAlertOpen}
            onClose={() => setAlertOpen(false)}
            title={alertContent.title}
            body={alertContent.body}
          />
          <Container maxW="container.lg">
            <form onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <MotionHeading
                  transition={{ duration: 0.8 }}
                  initial={{ x: -50 }}
                  whileInView={{ x: 0 }}
                  as="h3"
                  size="lg"
                >
                  Get Your Pet Travel Quote
                </MotionHeading>

                {/* Pet Owner's Information */}
                <FormControl isRequired>
                  <FormLabel htmlFor="full_name">Full name</FormLabel>
                  <Input
                    id="full_name"
                    type="text"
                    name="full_name"
                    value={formData.full_name}
                    onChange={handleChange}
                    style={formBorderStyle}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="email">Email Address</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    style={formBorderStyle}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="contact_number">Contact Number</FormLabel>
                  <Input
                    id="contact_number"
                    type="tel"
                    name="contact_number"
                    value={formData.contact_number}
                    onChange={handleChange}
                    style={formBorderStyle}
                  />
                </FormControl>

                {/* Pet Details */}
                <FormControl isRequired>
                  <FormLabel htmlFor="pet_type">Pet Type</FormLabel>
                  <ChakraSelect
                    id="pet_type"
                    name="pet_type"
                    value={formData.pet_type}
                    onChange={handleChange}
                    style={formBorderStyle}
                    bg="background"
                  // color="text"
                  >
                    <option value="cat">Cat</option>
                    <option value="dog">Dog</option>
                    <option value="other">Other</option>
                    {/* Additional pet types */}
                  </ChakraSelect>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="pet_breed">Pet Breed</FormLabel>
                  <Input
                    id="pet_breed"
                    name="pet_breed"
                    value={formData.pet_breed}
                    onChange={handleChange}
                    style={formBorderStyle}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="pet_weight">Pet Weight (lbs)</FormLabel>
                  <NumberInput>
                    <NumberInputField
                      id="pet_weight"
                      name="pet_weight"
                      value={formData.pet_weight}
                      onChange={handleChange}
                      style={formBorderStyle}
                    />
                  </NumberInput>
                </FormControl>

                {/* Travel Details */}
                <FormControl isRequired>
                  <FormLabel htmlFor="departure_city">Departure City</FormLabel>
                  <Select
                    id="departure_city"
                    name="departure_city"
                    options={cityOptions}
                    onChange={handleDepartureCityChange}
                    value={cityOptions.find(
                      (option) => option.value === formData.departure_city
                    )}
                    placeholder="Select a city..."
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused
                          ? theme.colors.secondary[300]
                          : theme.colors.secondary[500],
                      }),
                    }}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="arrival_city">Arrival City</FormLabel>
                  <Select
                    id="arrival_city"
                    name="arrival_city"
                    options={cityOptions}
                    onChange={handleArrivalCityChange}
                    value={cityOptions.find(
                      (option) => option.value === formData.arrival_city
                    )}
                    placeholder="Select a city..."
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused
                          ? theme.colors.secondary[300]
                          : theme.colors.secondary[500],
                      }),
                    }}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="departure_date">Departure Date</FormLabel>
                  <Input
                    id="departure_date"
                    type="date"
                    name="departure_date"
                    value={formData.departure_date}
                    onChange={handleChange}
                    style={formBorderStyle}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="additional_info">
                    Additional Information
                  </FormLabel>
                  <Textarea
                    id="additional_info"
                    name="additional_info"
                    value={formData.additional_info}
                    onChange={handleChange}
                    style={formBorderStyle}
                  />
                </FormControl>

                {/* <ButtonWrapper showSpinner={false} /> */}


                <MotionButton
                  transition={{ duration: 0.2 }}
                  initial={{ scale: 1 }}
                  whileHover={{ scale: 1.1 }}
                  type="submit"
                  colorScheme="secondary"
                  size="lg"
                >
                  Submit for Quote
                </MotionButton>
              </VStack>
            </form>
          </Container>
        </Box>
      </div>
    </CSSTransition>
  );
};

export default GetAQuote;
