import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    // useSystemColorMode: false,
  },
  fonts: {
    body: "Quicksand, sans-serif",
    heading: "Quicksand, sans-serif",
    // You can add more font styles as needed
  },
  colors: {
    primary: {
      50: "#ffe6cc", // Lightest Orange
      100: "#ffcc99",
      200: "#ffb366",
      300: "#ff9933",
      400: "#ff8000",
      500: "#ff6600", // Warm Orange (Middle)
      600: "#cc5200",
      700: "#993d00",
      800: "#662900",
      900: "#331400", // Darkest Orange
    },
    secondary: {
      50: "#e6f3ff", // Lightest Blue
      100: "#cce8ff",
      200: "#99d1ff",
      300: "#66baff",
      400: "#33a3ff",
      500: "#008cff", // Bright Blue (Middle)
      600: "#007acc",
      700: "#006699",
      800: "#004d66",
      900: "#003366", // Darkest Blue
    },
    background: "#FFFFFF", // White
    accent: {
      yellow: "#f1c40f", // Soft Yellow
      blue: "#3498db", // Light Blue
    },
    text: "#373f51", // Dark Gray
  },
});

export default theme;
