import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Flex,
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Image,
  VStack,
  Link as ChakraLink,
  useTheme,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

import logo_with_words from "../../assets/images/logo_with_words.webp";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const theme = useTheme();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Box bg="secondary.100">
      <Flex
        as="nav"
        maxW="container.xl"
        // bg={theme.colors.primary[300]}
        align="center"
        justify={{ base: "space-between", md: "space-around" }}
        padding="1"
        color="text"
        position="static"
        top="0"
        width="100%"
        zIndex="sticky"
        fontWeight="bold"
      >
        <ChakraLink
          as={RouterLink}
          to="/"
          marginRight={{ base: 0, md: 8 }}
          marginLeft={{ base: 4, md: 0 }}
          display="flex"
          alignItems="center"
          align="center"
          _hover={{ textDecoration: "none" }}
        >
          <Image src={logo_with_words} alt="AirPetTaxi Logo" mr="1" w="150px"
            loading="lazy" />
        </ChakraLink>
        <Box
          display={{ base: "block", md: "none" }}
          color="text"
          fontSize="xl"
          onClick={toggleMenu}
          fontWeight="bold"
          padding={"1rem"}
        >
          <HamburgerIcon boxSize={6} />
        </Box>
        {/* Desktop navigation links */}
        <Flex
          display={{ base: "none", md: "flex" }}
          alignItems="center"
          padding={"1rem"}
          textTransform={"uppercase"}
          fontWeight="bold"
        >
          <ChakraLink
            as={RouterLink}
            to="/"
            ml={{ base: 0, md: 4 }}
            fontSize="md"
            _hover={{
              textDecoration: "none",
              color: "secondary.700",
            }} // Apply Chakra UI styling on hover
          >
            Home
          </ChakraLink>
          <ChakraLink
            as={RouterLink}
            to="/about"
            ml={5}
            fontSize="md"
            _hover={{
              textDecoration: "none",
              color: theme.colors.secondary[400],
            }} // Apply Chakra UI styling on hover
          >
            About Us
          </ChakraLink>
          <ChakraLink
            as={RouterLink}
            to="/process"
            ml={6}
            fontSize="md"
            _hover={{
              textDecoration: "none",
              color: theme.colors.secondary[400],
            }} // Apply Chakra UI styling on hover
          >
            The Process
          </ChakraLink>

          <ChakraLink
            as={RouterLink}
            to="/contact"
            ml={5}
            fontSize="md"
            _hover={{
              textDecoration: "none",
              color: theme.colors.secondary[400],
            }} // Apply Chakra UI styling on hover
          >
            Contact
          </ChakraLink>
        </Flex>

        <Box display={{ base: "none", md: "flex" }}>
          {/* Get a Quote Button */}
          <Button
            as={RouterLink}
            to="/quote"
            color="background"
            colorScheme="purple"
            ml={4}
          >
            Get a Quote
          </Button>
          {/* Join Our Team Button */}
          <Button
            as={RouterLink}
            to="/join"
            color="background"
            colorScheme="primary"
            ml={4}
          >
            Join Our Team
          </Button>
        </Box>

        {/* Drawer for mobile menu */}
        <Drawer isOpen={isMenuOpen} placement="right" onClose={closeMenu}>
          <DrawerOverlay>
            <DrawerContent bg="secondary.100" color="text" h="100%">
              <DrawerCloseButton fontSize="xl" padding={"1rem"} />
              <DrawerHeader fontSize="2xl" display="flex" alignItems="center">
                <Image
                  src={logo_with_words}
                  w="150px"
                  alt="AirPetTaxi Logo"
                  mr="1"
                />
              </DrawerHeader>
              <DrawerBody>
                <VStack
                  spacing={6}
                  textTransform={"uppercase"}
                  fontWeight="bold"
                >
                  <ChakraLink
                    as={RouterLink}
                    to="/"
                    width={"100%"}
                    ml={4}
                    fontSize="xl"
                    fontWeight={600}
                    _hover={{
                      textDecoration: "none",
                      color: "secondary.700",
                    }} // Apply Chakra UI styling on hover
                    onClick={closeMenu}
                  >
                    Home
                  </ChakraLink>
                  <ChakraLink
                    as={RouterLink}
                    to="/about"
                    width={"100%"}
                    ml={4}
                    fontSize="xl"
                    fontWeight={600}
                    _hover={{
                      textDecoration: "none",
                      color: "secondary.700",
                    }} // Apply Chakra UI styling on hover
                    onClick={closeMenu}
                  >
                    About Us
                  </ChakraLink>
                  <ChakraLink
                    as={RouterLink}
                    to="/process"
                    width={"100%"}
                    ml={4}
                    fontSize="xl"
                    fontWeight={600}
                    _hover={{
                      textDecoration: "none",
                      color: "secondary.700",
                    }} // Apply Chakra UI styling on hover
                    onClick={closeMenu}
                  >
                    process
                  </ChakraLink>
                  <ChakraLink
                    as={RouterLink}
                    to="/contact"
                    width={"100%"}
                    ml={4}
                    fontSize="lg"
                    fontWeight={600}
                    _hover={{
                      textDecoration: "none",
                      color: "secondary.700",
                    }} // Apply Chakra UI styling on hover
                    onClick={closeMenu}
                  >
                    Contact
                  </ChakraLink>
                  {/* Get a Quote Button */}
                  <Button
                    as={RouterLink}
                    to="/quote"
                    size="lg"
                    color="background"
                    colorScheme="purple"
                    // ml={4}
                    onClick={closeMenu}
                  >
                    Get a Quote
                  </Button>
                  {/* Join Our Team Button */}
                  <Button
                    as={RouterLink}
                    to="/join"
                    size="lg"
                    color="background"
                    colorScheme="primary"
                    // ml={4}
                    onClick={closeMenu}
                  >
                    Join Our Team
                  </Button>
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Flex>
    </Box>
  );
};

export default Navbar;
