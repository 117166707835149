import React from "react";
import {
  Box,
  Flex,
  Image,
  Heading,
  Text,
  useTheme,
  VStack,
  Container,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faPaw,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";

import { motion } from "framer-motion";

import icon1 from "../assets/images/img1.webp";
import icon2 from "../assets/images/img3.webp";
import icon3 from "../assets/images/img2.webp";

const WhyChooseUs = () => {
  const theme = useTheme();

  const MotionFlex = motion(Flex);
  const MotionImage = motion(Image);

  const reasons = [
    {
      id: 1,
      icon: (
        <FontAwesomeIcon icon={faPaw} color={theme.colors.secondary[500]} />
      ),
      image: icon1,
      title: "Professional Pet Assistance in-Flight!",
      description:
        "Our Pet Flight Nanny Company Guarantees Stress-Free Travel for Your Beloved Pets.",
    },
    {
      id: 2,
      icon: (
        <FontAwesomeIcon icon={faHeart} color={theme.colors.secondary[500]} />
      ),
      image: icon2,
      title: "Every Detail, Every Step Managed by Us!",
      description:
        "Trust Our Pet Flight Nannies for Dedicated Attention to Your Four-Legged Companions During Flights.",
    },
    {
      id: 3,
      icon: (
        <FontAwesomeIcon
          icon={faShieldAlt}
          color={theme.colors.secondary[500]}
        />
      ),
      image: icon3,
      title: "USDA Licensed and Insured!",
      description: "We offer up to $5000 loss coverage.",
    },
  ];

  return (
    <Box bg="background" py="8">
      <Container maxW="container.lg">
        <Box textAlign="center" mb="8">
          <Heading as="h2" size="xl" textAlign="center" mb={6} color="text">
            Why Choose AirPetTaxi
          </Heading>
          <Text fontSize="lg" color={theme.colors.text} mt="4" py="8">
            Discover the reasons why AirPetTaxi is the best choice for pet
            transportation.
          </Text>
        </Box>
        <VStack spacing={8} align="stretch">
          {reasons.map((reason, index) => (
            <MotionFlex
              key={reason.id}
              initial={{ opacity: 0, x: -10 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              p="5"
              flexDirection={{ base: "column", md: "row" }}
              justifyContent="flex-start"
              alignItems="center"
            >
              <MotionImage
                whileHover={{ scale: 1.05 }}
                src={reason.image}
                alt={reason.title}
                loading="lazy"
                // borderRadius="100%"
                maxW={{ base: "100%", md: "20vw" }}
                objectFit="contain"
                px={{ base: "0", md: "5" }}
                opacity={index % 2 === 0 ? "0.8" : "1"}
              />

              <Box textAlign="start" maxW="500px">
                <Heading as="h3" size="xl" color={theme.colors.text}>
                  {reason.title}
                </Heading>
                <Text pt="2" color={theme.colors.text}>
                  {reason.description}
                </Text>
              </Box>
            </MotionFlex>
          ))}
        </VStack>
      </Container>
    </Box>
  );
};

export default WhyChooseUs;
