import {
  Box,
  Button,
  Select as ChakraSelect,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Textarea,
  VStack,
  useTheme
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";

import Select from "react-select";

import { motion } from "framer-motion";
import { Helmet } from "react-helmet";


import airlines from "../../airlines";

import joinBg from "../../assets/images/joinBg1.webp";

import CustomAlert from "../CustomAlert";

import airports from "../../airports";
import "../../assets/css/PageTransitions.css";
import "../../index.css";

const MotionHeading = motion(Heading);
const MotionButton = motion(Button);

const JoinOurTeam = () => {
  const theme = useTheme();

  const initialFormData = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    airline: "",
    willing_to_deliver_via_standby: true,
    closest_airport: "",
    monthly_deliveries: 1,
    referral_source: "Online Search",
    animal_lover_experience: "",
    available_start_date: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: "", body: "" });

  const whereDidYouHearAboutUsOptions = [
    "Online Search",
    "Social Media",
    "Friend or Family",
    "Advertisement",
    "Other",
  ];

  const airlineOptions = useMemo(() => {
    let options = [];
    Object.keys(airlines).forEach((code) => {
      options.push({
        name: "airline",
        label: `${airlines[code]}, ${code}`,
        value: `${airlines[code]}, ${code}`,
      });
    });
    return options;
  }, []);

  const handleAirlineCityChange = (option) => {
    setFormData({ ...formData, airline: option.value });
    // Additional logic when a city is selected
  };

  const airportOptions = useMemo(() => {
    let options = [];
    airports.map((airport) => {
      options.push({
        name: "closest_airport",
        label: `${airport.name}, ${airport.city}, ${airport.state}`,
        value: `${airport.name}, ${airport.city}, ${airport.state}`,
      });
    });
    return options;
  }, []);

  const handleAirportCityChange = (option) => {
    setFormData({ ...formData, closest_airport: option.value });
    // Additional logic when a city is selected
  };

  const showAlert = (title, body) => {
    setAlertContent({ title, body });
    setAlertOpen(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const api_link = "https://api.airpettaxi.com/join-team/";

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(api_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Reset form
        setFormData(initialFormData);
        showAlert("Success!", "Your form submitted successfully!");
      } else {
        showAlert(
          "Error",
          "There was an issue with the server, Please try again"
        );
      }
    } catch (error) {
      console.error("Error:", error);
      showAlert("Error", "There was an issue submitting the form");
    }
  };

  const formBorderStyle = {
    borderColor: theme.colors.secondary[500],
  };

  return (
  //   <CSSTransition classNames="page" timeout={500}>
  //     <div className="page-entered">
  <>
    <Helmet>
      <title>Join Our Team - AirPetTaxi</title>
      <meta
        name="description"
        content="Be a part of our team at AirPetTaxi. Explore exciting career opportunities in pet transportation and apply now to join our growing family."
      />
      <meta
        name="keywords"
        content="careers, pet transportation jobs, join team, AirPetTaxi employment, work with pets"
      />
      {/* Other SEO relevant tags */}

      <script>
        {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                  n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '2899328293629083');
              fbq('track', 'PageView'); 
            `}
      </script>
      <noscript>
        {`
                <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=2899328293629083&ev=PageView&noscript=1"
                `}
      </noscript>
    </Helmet>
    <Box overflowX="hidden">
      {/* Your form JSX */}
      <CustomAlert
        isOpen={isAlertOpen}
        onClose={() => setAlertOpen(false)}
        title={alertContent.title}
        body={alertContent.body}
      />

      {/* Join Our Team Banner Section */}
      <Box
        bgImage={joinBg}
        bgPos={"bottom"}
        bgSize="cover"
        textAlign="center"
        padding="4rem"
        position="relative"
        minHeight="max(calc(100vh - 60px), 700px)"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        {/* <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bg="blackAlpha.200" // Semi-transparent black background
              zIndex="1" // Ensures overlay is behind the content
            /> */}
        <Container
          maxW="container.xl"
          color="background"
          position="relative"
          zIndex="2"
          bg="blackAlpha.600"
          borderRadius="xl"
        >
          <MotionHeading
            transition={{ duration: 0.8 }}
            initial={{ x: -50 }}
            whileInView={{ x: 0 }}
            as="h1"
            py="2"
            px="1"
            size="3xl"
            fontWeight="bold"
            mb="4"
          >
            Join Our Team
          </MotionHeading>
          <MotionHeading
            transition={{ duration: 0.8 }}
            initial={{ x: 90 }}
            whileInView={{ x: 0 }}
            as="h2"
            size="md"
            mb="4"
          >
            Interested in working with us? Leave your information below!
          </MotionHeading>
        </Container>
      </Box>

      {/* Join Our Team Form Section */}
      <Box paddingY="8" bg={theme.colors.background}>
        <Container maxW="container.lg">
          <VStack spacing="8" align="start">
            <MotionHeading
              transition={{ duration: 0.8 }}
              initial={{ x: 80 }}
              whileInView={{ x: 0 }}
              as="h2"
              size="xl"
            >
              Submit Your Information
            </MotionHeading>
            <form onSubmit={handleSubmit} onChange={handleChange}>
              <VStack spacing="4" align="start" w="100%">
                {/* Name Fields */}
                <Flex justify="space-between" w="100%" mb="4">
                  <FormControl flex="1" mr="2" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                      type="text"
                      size="lg"
                      style={formBorderStyle}
                    />
                  </FormControl>
                  <FormControl flex="1" ml="2" isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                      type="text"
                      size="lg"
                      style={formBorderStyle}
                    />
                  </FormControl>
                </Flex>

                {/* Email, Phone Fields */}
                <FormControl isRequired>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    type="tel"
                    size="lg"
                    style={formBorderStyle}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    type="email"
                    size="lg"
                    style={formBorderStyle}
                  />
                </FormControl>

                {/* Airline, Standby Delivery, Closest Airport Fields */}
                <FormControl isRequired>
                  <FormLabel>Which airline do you work for?</FormLabel>
                  <Select
                    id="airline"
                    name="airline"
                    options={airlineOptions}
                    onChange={handleAirlineCityChange}
                    value={airlineOptions.find(
                      (option) => option.value === formData.airline
                    )}
                    placeholder="Select an airline..."
                    styles={{
                      control: (baseStyles, airline) => ({
                        ...baseStyles,
                        borderColor: airline.isFocused
                          ? theme.colors.secondary[300]
                          : theme.colors.secondary[500],
                      }),
                    }}
                  />
                  {/* <Input
                        name="airline"
                        value={formData.airline}
                        onChange={handleChange}
                        type="text"
                        size="lg"
                        style={formBorderStyle}
                      /> */}
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>
                    Are you willing to deliver pets via standby?
                  </FormLabel>
                  <ChakraSelect
                    name="willing_to_deliver_via_standby"
                    value={formData.willing_to_deliver_via_standby}
                    onChange={handleChange}
                    size="lg"
                    style={formBorderStyle}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </ChakraSelect>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>
                    What major airport is closest to where you live?
                  </FormLabel>
                  <Select
                    id="closest_airport"
                    name="closest_airport"
                    options={airportOptions}
                    onChange={handleAirportCityChange}
                    value={airlineOptions.find(
                      (option) => option.value === formData.closest_airport
                    )}
                    placeholder="Select an airport..."
                    styles={{
                      control: (baseStyles, airline) => ({
                        ...baseStyles,
                        borderColor: airline.isFocused
                          ? theme.colors.secondary[300]
                          : theme.colors.secondary[500],
                      }),
                    }}
                  />
                </FormControl>

                {/* Monthly Deliveries Field */}
                <FormControl isRequired>
                  <FormLabel>
                    How many deliveries are you available to complete on a
                    monthly basis?
                  </FormLabel>
                  <ChakraSelect
                    name="monthly_deliveries"
                    value={formData.monthly_deliveries}
                    onChange={handleChange}
                    size="lg"
                    style={formBorderStyle}
                  >
                    <option value="1">1</option>
                    <option value="2-4">2-4</option>
                    <option value="5-10">5-10</option>
                  </ChakraSelect>
                </FormControl>

                {/* How did you hear about us? Field */}
                <FormControl isRequired>
                  <FormLabel>How did you hear about us?</FormLabel>
                  <ChakraSelect
                    id="referral_source"
                    name="referral_source"
                    onChange={handleChange}
                    value={formData.referral_source}
                    style={formBorderStyle}
                  >
                    {whereDidYouHearAboutUsOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </ChakraSelect>
                  {/* <Input
                        name="referral_source"
                        value={formData.referral_source}
                        onChange={handleChange}
                        type="text"
                        size="lg"
                        style={formBorderStyle}
                      /> */}
                </FormControl>

                {/* Animal Lover and Experience Fields */}
                <FormControl isRequired>
                  <FormLabel>
                    Are you an animal lover & do you have experience
                    handling puppies and kittens?
                  </FormLabel>
                  <Textarea
                    name="animal_lover_experience"
                    value={formData.animal_lover_experience}
                    onChange={handleChange}
                    type="text"
                    size="lg"
                    style={formBorderStyle}
                  />
                </FormControl>

                {/* Start Date Field */}
                <FormControl isRequired>
                  <FormLabel>
                    What date are you available to start?
                  </FormLabel>
                  <Input
                    name="available_start_date"
                    value={formData.available_start_date}
                    onChange={handleChange}
                    type="date"
                    size="lg"
                    style={formBorderStyle}
                  />
                </FormControl>

                {/* Submit Button */}
                <MotionButton
                  transition={{ duration: 0.3 }}
                  initial={{ scale: 1 }}
                  whileHover={{ scale: 1.2 }}
                  type="submit"
                  colorScheme="secondary"
                  color="Background"
                  size="lg"
                >
                  Submit
                </MotionButton>
              </VStack>
            </form>
          </VStack>
        </Container>
      </Box>
    </Box>
  </>
  );
};

export default JoinOurTeam;
