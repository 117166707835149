import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button
} from '@chakra-ui/react';

const CustomAlert = ({ isOpen, onClose, title, body }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} colorScheme="primary">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {body}
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="primary" onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CustomAlert;
