import {
  Box,
  Container,
  Divider,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

import compassion from "../../assets/images/compassion.webp";
import innovation from "../../assets/images/innovation.webp";
import trust from "../../assets/images/trust.webp";

const OurVisionAndValues = () => {

  const MotionContainer = motion(Container);
  const MotionHeading = motion(Heading);
  const MotionText = motion(Text);
  const MotionImage = motion(Image);

  // Vision and Values content
  const visionAndValuesContent = [
    {
      title: "Compassion & Care",
      description:
        "Every decision we make is guided by our deep-rooted love for pets.",
      image: compassion,
    },
    {
      title: "Integrity & Trust",
      description:
        "We believe trust is the foundation of our service, and we uphold the highest standards of integrity.",
      image: trust,
    },
    {
      title: "Innovation & Excellence",
      description:
        "Continuously innovating to enhance the travel experience for pets and their owners.",
      image: innovation,
    },
    // Add more vision and values as needed
  ];

  return (
    <Box bg="background" py="8" color="text">
      <MotionContainer
        transition={{ duration: 0.8, delay: 0.3 }}
        initial={{ opacity: 0, x: 60 }}
        whileInView={{ x: 0, opacity: 1 }}
        maxW="container.xl"
      >
        <VStack spacing={8} align="center">
          <MotionHeading
            transition={{ duration: 0.8, delay: 0.4 }}
            initial={{ opacity: 0, x: -140 }}
            whileInView={{ x: 0, opacity: 1 }}
            as="h2"
            size="xl"
            textAlign="center"
          >
            Our Vision and Values
          </MotionHeading>
          <MotionText
            transition={{ duration: 0.8, delay: 0.3 }}
            initial={{ opacity: 0, x: 150 }}
            whileInView={{ x: 0, opacity: 1 }}
            fontSize="lg"
            textAlign="center"
            maxW="container.md"
          >
            At the heart of AirPetTaxi are our guiding principles and
            aspirations that shape our approach to pet transportation.
          </MotionText>
          <Divider my={5} />
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            {visionAndValuesContent.map((value, index) => (
              <VStack key={index} spacing={4} align="center">
                <MotionImage
                  transition={{ duration: 0.3 }}
                  initial={{ scale: 0.8 }}
                  whileInView={{ scale: 1 }}
                  whileHover={{ x: 0, scale: 1.2 }}
                  borderRadius="full"
                  loading="lazy"
                  boxSize="160px"
                  src={value.image}
                  alt={value.title}
                  objectFit="cover"
                />
                <MotionHeading
                  transition={{ duration: 0.8, delay: 0.3 }}
                  initial={{ opacity: 0, x: -160 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  as="h3"
                  size="md"
                >
                  {value.title}
                </MotionHeading>
                <MotionText
                  transition={{ duration: 0.8, delay: 0.4 }}
                  initial={{ opacity: 0, x: 100 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  textAlign="center"
                >
                  {value.description}
                </MotionText>
              </VStack>
            ))}
          </SimpleGrid>
        </VStack>
      </MotionContainer>
    </Box>
  );
};

export default OurVisionAndValues;
