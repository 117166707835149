import {
  Box,
  Container,
  Heading,
  Text
} from "@chakra-ui/react";
import React from "react";
import aboutUsImage from "../../assets/images/aboutBg.webp";

import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { CSSTransition } from "react-transition-group";

import "../../assets/css/PageTransitions.css";
import OurVisionAndValues from "./OurVisionAndValues ";
import WhyWeAreDifferent from "./WhyWeAreDifferent";

const MotionContainer = motion(Container);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);

const About = () => {
  return (
    <CSSTransition classNames="page" timeout={500}>
      <div className="page-entered">
        <Helmet>
          <title>About Us - AirPetTaxi</title>
          <meta
            name="description"
            content="Learn more about AirPetTaxi, our mission, values, and how we ensure the safest travel experiences for your pets. Our team is dedicated to providing top-notch pet transportation services."
          />
          <meta
            name="keywords"
            content="about AirPetTaxi, pet transportation, pet travel services, pet flight nanny, safe pet transport"
          />
          {/* Additional SEO tags */}
          {/* Meta Pixel Code */}
          <script>
            {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                  n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '2899328293629083');
              fbq('track', 'PageView'); 
              `}
          </script>
          <noscript>
            {`
            <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=2899328293629083&ev=PageView&noscript=1" />
            `}
          </noscript>
          {/* End Meta Pixel Code  */}
        </Helmet>
        <Box overflowX="hidden">
          {/* Hero Section */}
          <Box
            id="hero-section"
            bgImage={aboutUsImage}
            bgPos={"center"}
            bgSize="cover"
            bgRepeat="no-repeat"
            textAlign="center"
            padding="4rem"
            position="relative"
            minHeight="max(calc(100vh - 60px), 700px)"
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="flex-end"
          >
            {/* Overlay with filter */}
            {/* <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bg="blackAlpha.100"
              zIndex="1" // Ensures overlay is behind the content
          /> */}
            <Box position="relative" zIndex="2">
              <MotionHeading
                transition={{ duration: 0.8, delay: 0.2 }}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ x: 0, opacity: 1 }}
                as="h1"
                //  bgColor="blackAlpha.500"
                borderRadius="xl"
                p="2"
                size="3xl"
                color="black"
              >
                About AirPetTaxi
              </MotionHeading>
            </Box>
          </Box>

          {/* Our Story */}
          <MotionContainer
            maxW="container.lg"
            py="8"
            transition={{ duration: 0.8 }}
            initial={{ opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            bgColor="background"
            color="text"
          >
            <MotionHeading
              as="h2"
              transition={{ duration: 0.8, delay: 0.2 }}
              initial={{ opacity: 0, x: 150 }}
              whileInView={{ x: 0, opacity: 1 }}
              size="xl"
              mb="4"
            >
              Our Story
            </MotionHeading>
            <MotionText
              transition={{ duration: 0.8, delay: 0.2 }}
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ x: 0, opacity: 1 }}
              fontSize="lg"
              mb={4}
            >
              It all began with a simple belief: our pets deserve the same
              comfort and safety in travel as we do. From our very first pet
              transport, we've been committed to unmatched care for our furry
              passengers. AirPetTaxi was founded on the passion of pet lovers
              who understood the stress of moving pets across cities and
              countries. We've since grown into a trusted community, ensuring
              that every pet's journey is filled with warmth and security.
            </MotionText>
            <MotionText
              transition={{ duration: 0.8, delay: 0.3 }}
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ x: 0, opacity: 1 }}
              fontSize="lg"
              mb={4}
            >
              Our team, composed of pet owners and animal enthusiasts, knows
              firsthand the concerns that come with pet travel. That's why we've
              dedicated ourselves to a service that stands for more than just
              transportation; we provide peace of mind. With each flight, our
              professional flight nannies go above and beyond, turning a
              potentially anxious experience into a comfortable adventure for
              your pet.
            </MotionText>
            <MotionText
              transition={{ duration: 0.8, delay: 0.4 }}
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ x: 0, opacity: 1 }}
              t
              fontSize="lg"
            >
              As we continue to expand our services, our mission remains the
              same: to set the highest standard in pet transportation, where
              safety and comfort are just the beginning. We're more than a
              company; we're your pet's companion on the journey home.
            </MotionText>
            {/* Additional content */}
          </MotionContainer>

          {/* Meet the Team */}
          {/* <Box bg={theme.colors.background} py="8">
                        <Container maxW="container.lg">
                            <MotionHeading
                                transition={{ duration: 0.8, delay: 0.3 }}
                                initial={{ opacity: 0, x: 60 }}
                                whileInView={{ x: 0, opacity: 1 }} as="h2" size="xl" textAlign="center" mb="6">Meet the Team</MotionHeading>
                            <MeetTheTeam />
                        </Container>
                    </Box> */}

          <WhyWeAreDifferent />

          <OurVisionAndValues />
        </Box>
      </div>
    </CSSTransition>
  );
};

export default About;
