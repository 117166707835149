import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  Textarea,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFacebook as faFacebookBrand, faInstagram as faInstagramBrand } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone
} from "@fortawesome/free-solid-svg-icons";

import contactBg from "../../assets/images/contactBg.webp";

import { CSSTransition } from "react-transition-group";
import CustomAlert from "../CustomAlert";

import "../../assets/css/PageTransitions.css";
import "../../index.css";

const MotionContainer = motion(Container);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionButton = motion(Button);

const Contact = () => {
  const theme = useTheme();

  const api_link = "https://api.airpettaxi.com/contact/";

  const initialFormData = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: "", body: "" });

  const showAlert = (title, body) => {
    setAlertContent({ title, body });
    setAlertOpen(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    // Handle form submission logic here
    try {
      const response = await fetch(api_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Handle successful submission
        console.log("Form submitted successfully");
        setFormData(initialFormData);
        showAlert("Success!", "Your message submitted successfully!");
      } else {
        // Handle server-side errors
        const errorData = await response.json();
        console.error("Form submission error:", errorData.detail);
        showAlert(
          "Error",
          "There was an issue with the server, Please try again"
        );
      }
    } catch (error) {
      // Handle network errors
      console.error("Network error:", error);
      showAlert("Error", "There was an issue submitting the form");
    }
  };

  const formBorderStyle = {
    borderColor: theme.colors.secondary[300],
  };

  return (
    <CSSTransition classNames="page" timeout={500}>
      <div className="page-entered">
        <Helmet>
          <title>Contact Us - AirPetTaxi</title>
          <meta
            name="description"
            content="Get in touch with AirPetTaxi for safe and comfortable pet transportation services. Contact us for inquiries, support, or to book our services."
          />
          <meta
            name="keywords"
            content="AirPetTaxi contact, pet transportation service, contact pet taxi, pet travel inquiries"
          />
          {/* Other SEO relevant tags */}
          {/* Meta Pixel Code */}
          <script>
            {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '2899328293629083');
                fbq('track', 'PageView'); 
            `}
          </script>
          <noscript>
            {`
            <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=2899328293629083&ev=PageView&noscript=1" />
            `}
          </noscript>
          {/* End Meta Pixel Code  */}
        </Helmet>
        {/* Rest of your component */}
        <Box overflowX="hidden">
          {/* Your form JSX */}
          <CustomAlert
            isOpen={isAlertOpen}
            onClose={() => setAlertOpen(false)}
            title={alertContent.title}
            body={alertContent.body}
          />
          {/* Contact Banner Section */}
          <Box
            bgImage={contactBg}
            bgPos={"center"}
            bgSize="cover"
            bgRepeat="no-repeat"
            position="relative"
            minHeight="calc(100vh - 64px)" // Subtract the navbar height (adjust as needed)
            display="flex"
            alignItems="end"
            justifyContent="center"
            textAlign="center"
            color="text"
            padding="4"
          >
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bg="blackAlpha.100" // Semi-transparent black background
              zIndex="1" // Ensures overlay is behind the content
            />
            <MotionContainer position="relative" zIndex="2" color="white">
              <Box p="2"
                bg="blackAlpha.400"
                borderRadius="xl"
              >
                <MotionHeading
                  transition={{ duration: 0.8 }}
                  initial={{ x: -50 }}
                  viewport={{ once: true }}
                  whileInView={{ x: 0 }}
                  as="h1"
                  size="3xl"
                  fontWeight="bold"
                  mb="4"
                >
                  Contact Us
                </MotionHeading>
                <MotionHeading
                  transition={{ duration: 0.8 }}
                  initial={{ x: 120 }}
                  viewport={{ once: true }}
                  whileInView={{ x: 0 }}
                  as="h2"
                  size="md"
                  mb="8"
                >
                  Have questions or want to get in touch? Send us a message!
                </MotionHeading>
              </Box>
            </MotionContainer>
          </Box>

          {/* Contact Information and Form Section */}
          <Box paddingY="8" minH="100vh" bg={theme.colors.background}>
            <Container maxW="container.lg" color="text">
              <Flex
                direction={{ base: "column", md: "row" }}
                justify="space-between"
                align={{ base: "start", md: "center" }}
                spacing="8"
              >
                {/* Contact Information Section */}
                <Box flex="1" paddingY="8" align="start">
                  <VStack spacing="4" align="start" textAlign="start">
                    <MotionHeading
                      transition={{ duration: 0.5 }}
                      initial={{ x: -75 }}
                      whileInView={{ x: 0 }}
                      as="h3"
                      size="lg"
                    >
                      For immediate assistance or inquiries, you can reach us
                      at:
                    </MotionHeading>
                    <MotionText
                      transition={{ duration: 0.5 }}
                      initial={{ x: 75 }}
                      whileInView={{ x: 0 }}
                      marginBottom="2"
                    >
                      <FontAwesomeIcon
                        icon={faPhone}
                        style={{ marginRight: "4px" }}
                      />{" "}
                      Phone: <Link href="tel:+17033006963">703-300-6963</Link>
                    </MotionText>
                    <MotionText
                      transition={{ duration: 0.5 }}
                      initial={{ x: 75 }}
                      whileInView={{ x: 0 }}
                      marginBottom="2"
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ marginRight: "4px" }}
                      />{" "}
                      Email:{" "}
                      <Link href="mailto:airpettaxillc@gmail.com">
                        airpettaxillc@gmail.com
                      </Link>
                    </MotionText>
                    <MotionText
                      transition={{ duration: 0.5 }}
                      initial={{ x: 75 }}
                      whileInView={{ x: 0 }}
                      marginBottom="2"
                    >
                      <FontAwesomeIcon
                        icon={faInstagramBrand}
                        style={{ marginRight: "4px" }}
                      />{" "}
                      Instagram:{" "}
                      <Link
                        href="https://www.instagram.com/airpettaxi"
                        isExternal
                      >
                        airpettaxi
                      </Link>
                    </MotionText>
                    <MotionText
                      transition={{ duration: 0.5 }}
                      initial={{ x: 75 }}
                      whileInView={{ x: 0 }}
                      marginBottom="2"
                    >
                      <FontAwesomeIcon
                        icon={faFacebookBrand}
                        style={{ marginRight: "4px" }}
                      />{" "}
                      Facebook:{" "}
                      <Link
                        href="https://www.facebook.com/airpettaxi"
                        isExternal
                      >
                        airpettaxi
                      </Link>
                    </MotionText>
                  </VStack>
                </Box>

                {/* Contact Form Section */}
                <Box flex="1">
                  <VStack spacing="8" align="start">
                    <MotionHeading
                      transition={{ duration: 0.8 }}
                      initial={{ x: 125 }}
                      whileInView={{ x: 0 }}
                      as="h2"
                      size="xl"
                    >
                      Send Us a Message
                    </MotionHeading>
                    <form onSubmit={handleSubmit} onChange={handleChange}>
                      <VStack spacing="4" align="start" w="100%">
                        {/* Add form fields based on your requirements */}
                        <Flex justify="space-between" w="100%" mb="4">
                          <FormControl flex="1" mr="2" isRequired>
                            <FormLabel>First Name</FormLabel>
                            <Input
                              name="first_name"
                              onChange={handleChange}
                              value={formData.first_name}
                              type="text"
                              size="lg"
                              style={formBorderStyle}
                            />
                          </FormControl>
                          <FormControl flex="1" ml="2" isRequired>
                            <FormLabel>Last Name</FormLabel>
                            <Input
                              name="last_name"
                              onChange={handleChange}
                              value={formData.last_name}
                              type="text"
                              size="lg"
                              style={formBorderStyle}
                            />
                          </FormControl>
                        </Flex>

                        {/* Email, Phone Fields */}
                        <FormControl isRequired>
                          <FormLabel>Phone</FormLabel>
                          <Input
                            name="phone"
                            onChange={handleChange}
                            value={formData.phone}
                            type="tel"
                            size="lg"
                            style={formBorderStyle}
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <FormLabel>Email</FormLabel>
                          <Input
                            name="email"
                            onChange={handleChange}
                            value={formData.email}
                            type="email"
                            size="lg"
                            style={formBorderStyle}
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <FormLabel>Your messsage</FormLabel>
                          <Textarea
                            name="message"
                            onChange={handleChange}
                            value={formData.message}
                            size="lg"
                            resize="vertical"
                            style={formBorderStyle}
                          />
                        </FormControl>

                        <MotionButton
                          transition={{ duration: 0.2 }}
                          initial={{ scale: 1 }}
                          whileHover={{ scale: 1.1 }}
                          type="submit"
                          colorScheme="secondary"
                          color="white"
                          size="lg"
                        >
                          Send Message
                        </MotionButton>
                      </VStack>
                    </form>
                  </VStack>
                </Box>
              </Flex>
            </Container>
          </Box>
        </Box>
      </div>
    </CSSTransition>
  );
};

export default Contact;
