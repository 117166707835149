import {
  Box,
  Button,
  Link as ChakraLink,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { Link as ScrollLink } from "react-scroll";

import "../../assets/css/PageTransitions.css";
import "../../index.css";

import Testimonials from "../Testimonials";
import WhyChooseUs from "../WhyChooseUs";

import mainBG from "../../assets/images/mainBg.webp"; // Ensure this is a high-quality, relevant image

import card1 from "../../assets/images/card1.webp";
import card2 from "../../assets/images/card2.webp";
import card3 from "../../assets/images/card3.webp";

const MotionBox = motion(Box);
const MotionContainer = motion(Container);
const MotionFlex = motion(Flex);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionSimpleGrid = motion(SimpleGrid);
const MotionButton = motion(Button);

const Home = () => {
  const theme = useTheme();

  const images = useMemo(() => ({
    mainBG: mainBG,
    card1: card1,
    card2: card2,
    card3: card3,
  }), []);

  const heroContent = useMemo(() => (
    <>
      <MotionHeading
        as="h2"
        transition={{ duration: 0.4, delay: 0.2 }}
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ x: 0, opacity: 1 }}
        size={{ base: "xl", md: "2xl" }}
        fontWeight="bold"
        color="black"
        pt={6}
      >
        Safe & Comfortable Journeys for Your Furry Friends
      </MotionHeading>
      <Box pb={4}>
        <MotionText
          transition={{ duration: 0.4, delay: 0.2 }}
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ x: 0, opacity: 1 }}
          fontSize="lg"
          my={{ base: "2", md: "2" }}
          borderRadius="lg"
          px={2}
          fontWeight="bold"
          bg="whiteAlpha.800"
          color="text"
        >
          We ensure a secure and smooth travel experience for your
          beloved pets.
        </MotionText>
        <ScrollLink to="services-section" smooth duration={400}>
          <MotionButton
            transition={{ duration: 0.4, delay: 0.2 }}
            initial={{ opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            colorScheme="secondary"
            color="white"
            size="lg"
            mb={{ base: "5", md: "10" }}
          >
            Explore Services
          </MotionButton>
        </ScrollLink>
      </Box>
    </>
  ), []);

  const seoMetaData = useMemo(() => (
    <Helmet>
      <title>AirPetTaxi - Safe & Comfortable Pet Journeys</title>
      <meta
        name="description"
        content="Ensure a secure and smooth travel experience for your beloved pets with AirPetTaxi. Professional pet transportation services you can trust."
      />
      <meta
        name="keywords"
        content="Pet Shipping, Animal Transport, Pet Relocation, Pet Transport Services, Pet Shipping Companies, International Pet Shipping, Domestic Pet Shipping, Pet Cargo Services, Pet Flight Services, Pet delivery, Pet Courier Services, Pet Shipping Rates, Pet Shipping Costs, Pet Travel Services, Pet Moving Companies, Pet Air Transportation, Flight nanny for pets, Puppy flight nanny, Live animal shipping, Pet nanny transport, Dog nanny transportation, Cat nanny transportation, Puppy nanny transportation, Dog delivery service, Cat delivery service, Pet delivery services, Deliver pets, Delivery cats, Delivery kittens, Cattery locations, Pet moving, Cat moving service, Cat couriers, Cat courier service, Cat transporter, Dog transport, Cat transport, Best pet transportation services, Pet transport, Animal transport, Animal transport services, Domestic pet shipping, Domestic pets air transport, Pet air transport reviews, Domestic animal shipping, Domestic pet shipping services, How much to ship a dog, How much to ship a cat, How much is it to ship a dog, How much is it to ship a cat, How much does it cost to ship a dog , How much does it cost to ship a cat"
      />
      {/* Other relevant SEO tags */}
      {/* Meta Pixel Code */}
      <script>
        {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '2899328293629083');
                fbq('track', 'PageView'); 
                `}
      </script>
      <noscript>
        {`
                <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=2899328293629083&ev=PageView&noscript=1" />
            `}
      </noscript>
      {/* End Meta Pixel Code  */}
      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Organization",
          name: "AirPetTaxi",
          url: "http://www.airpettaxi.com",
          logo: "http://www.airpettaxi.com/logo.png",
          sameAs: [
            "http://www.facebook.com/airpettaxi",
            "http://www.instagram.com/airpettaxi",
          ],
        })}
      </script>

      {/* Open Graph / Facebook */}
      {/* ... */}
    </Helmet>
  ), []);

  const MemoizedWhyChooseUs = React.memo(WhyChooseUs);
  const MemoizedTestimonials = React.memo(Testimonials);



  return (
    <>
      {seoMetaData}

      <Box position="relative">
        {/* Hero Section */}
        <Box
          id="hero-section"
          as="section"
          // bgImage={{ base: mainBGMobile, sm: mainBG }}
          bgColor="Background"
          bgImage={images.mainBG}
          bgPos={"center"}
          bgSize="contain"
          bgRepeat="no-repeat"
          bgClip="content-box"
          textAlign="center"
          minHeight="100vh"
          // py={{ base: "0", md: "10" }}
          position="relative"
        >
          {/* Content */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="column"
            minH="calc(100vh - 80px)"
          >
            {heroContent}
          </Box>
          {/*  bg={{ base: "nonde", md: "blackAlpha.400" }} borderRadius="xl" */}
        </Box>

        {/* Services Preview Section */}
        <MotionBox
          id="services-section"
          as="section"
          // initial={{ opacity: 0 }}
          // whileInView={{ opacity: 1 }}
          // transition={{ duration: 0.4 }}
          bg="secondary.50"
          textAlign="center"
          py={8}
        >
          <Container
            maxW="container.xl"
            textAlign="center"
            minH="80vh"
          // display="flex"
          // flexDirection="column"
          // justifyContent="space-around"
          >
            <Box>
              <MotionHeading
                transition={{ duration: 0.4, delay: 0.2 }}
                initial={{ opacity: 0, x: -40 }}
                whileInView={{ x: 0, opacity: 1 }}
                color="text"
                as="h2"
                size="xl"
                mb={5}
              >
                Our Services
              </MotionHeading>

              <MotionText
                color="text"
                transition={{ duration: 0.4, delay: 0.2 }}
                initial={{ opacity: 0, x: -70 }}
                whileInView={{ x: 0, opacity: 1 }}
                fontSize="lg"
                mb={4}
              >
                We offer a variety of pet transportation services.
              </MotionText>
            </Box>

            <MotionSimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              spacing={10}
              initial="hidden"
              animate="visible"
              // variants={{
              //   visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
              //   hidden: { opacity: 0 },
              // }}
            >
              <MotionBox
                display="flex"
                justifyContent="space-around"
                flexDirection="column"
                // whileHover={{ scale: 1.05 }}
                // whileInView={{ y: 0, opacity: 1 }}
                // initial={{ y: 10, opacity: 0 }}
                // transition={{ duration: 0.4, delay: 0.2 }}
                textAlign="left"
                shadow="lg"
                p={4}
                py={8}
                borderRadius="lg"
                bgColor="secondary.200"
                color="text"
              >
                <Image
                  src={images.card1}
                  alt="Service 1 Image"
                  loading="lazy"
                  objectFit="contain"
                  borderRadius="25px"
                  pb={5}
                />
                <Heading textAlign="center" as="h3" size="lg" mt={3}>
                  Pet Transport & Accompanying
                </Heading>
                <Text mt={2}>
                  Assisting with transporting pets by accompanying them on
                  flights. Ideal for pet owners unable to travel with their
                  pets or preferring not to send them via cargo.
                </Text>
              </MotionBox>

              <MotionBox
                display="flex"
                justifyContent="space-around"
                flexDirection="column"
                // whileHover={{ scale: 1.05 }}
                // initial={{ y: 10, opacity: 0 }}
                // transition={{ duration: 0.5, delay: 0.2 }}
                // whileInView={{ y: 0, opacity: 1 }}
                textAlign="left"
                shadow="lg"
                p={4}
                py={8}
                borderRadius="lg"
                bgColor="secondary.200"
                color="text"
              >
                {/* <Icon  Transport Icon /> */}
                <Image
                  src={images.card2}
                  alt="Service 1 Image"
                  loading="lazy"
                  objectFit="cover"
                  borderRadius="25px"
                />

                <Heading textAlign="center" as="h3" size="lg" mt={3}>
                  Personalized Care & Supervision
                </Heading>
                <Text mt={2}>
                  Providing personalized care, attention, and supervision to
                  ensure pets have a safe and comfortable journey.
                </Text>
              </MotionBox>

              <MotionBox
                display="flex"
                justifyContent="space-around"
                flexDirection="column"
                // whileHover={{ scale: 1.05 }}
                // initial={{ y: 10, opacity: 0 }}
                // transition={{ duration: 0.6, delay: 0.2 }}
                // whileInView={{ y: 0, opacity: 1 }}
                textAlign="left"
                shadow="lg"
                p={4}
                py={8}
                borderRadius="lg"
                bgColor="secondary.200"
                color="text"
              >
                {/* <Icon  Transport Icon /> */}
                <Image
                  src={images.card3}
                  alt="Service 1 Image"
                  loading="lazy"
                  objectFit="cover"
                  borderRadius="25px"
                />

                <Heading textAlign="center" as="h3" size="lg" mt={3}>
                  Documentation & Pet Comfort
                </Heading>
                <Text mt={2}>
                  Managing all paperwork and necessary documentation, ensuring
                  the pet's comfort during the flight, including feeding,
                  bathroom breaks, and companionship.
                </Text>
              </MotionBox>
            </MotionSimpleGrid>
          </Container>
        </MotionBox>

        <MemoizedWhyChooseUs />

        {/* Testimonials Section */}
        <MotionFlex
          transition={{ duration: 0.5, delay: 0.2 }}
          initial={{ opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          id="testimonials-section"
          as="section"
          bg="secondary.50"
          flexDirection="column"
          alignItems="center"
          paddingY="8"
          minH="100vh"
        >
          <MotionHeading
            transition={{ duration: 0.5, delay: 0.2 }}
            initial={{ opacity: 0, x: -80 }}
            whileInView={{ x: 0, opacity: 1 }}
            as="h2"
            size="xl"
            mb={5}
            textAlign="center"
          >
            What Our Customers Say
          </MotionHeading>
          <VStack spacing={4}>
            <MemoizedTestimonials />
          </VStack>
        </MotionFlex>

        {/* Call to Action Section */}
        <MotionFlex
          id="cta-section"
          as="section"
          bg="background"
          color={theme.colors.text}
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          paddingY="8"
          minH="max(50vh, 200px)"
        >
          <MotionContainer
            transition={{ duration: 0.5, delay: 0.2 }}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ x: 0, opacity: 1 }}
            maxW="container.lg"
          >
            <Heading as="h2" size="xl" fontWeight="bold">
              Ready for a Stress-Free Pet Journey?
            </Heading>
            <ChakraLink href="/quote">
              <MotionButton
                whileHover={{ scale: 1.1 }}
                colorScheme="secondary"
                color="white"
                size="lg"
                mt={4}
              >
                Get Started
              </MotionButton>
            </ChakraLink>
          </MotionContainer>
        </MotionFlex>
      </Box>
    </>
  );
};

export default Home;
