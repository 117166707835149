import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
  Icon,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md"; // This is an example icon from react-icons
import { motion } from "framer-motion";

const WhyWeAreDifferent = () => {
  const theme = useTheme();

  const MotionBox = motion(Box);
  const MotionContainer = motion(Container);
  const MotionHeading = motion(Heading);
  const MotionText = motion(Text);
  const MotionListItem = motion(ListItem);
  const MotionImage = motion(Image);

  // Points that make the company different
  const uniquePoints = [
    "Comprehensive pet care from door to door.",
    "Real-time updates throughout your pet's journey.",
    "Personalized travel itineraries for every pet.",
    "Dedicated pet travel consultants to tailor your pet's journey to their needs.",
    "Partnerships with top-tier pet-friendly airlines to ensure quality service.",
    "A trusted network of veterinarians on call to provide health checks and support.",
    "State-of-the-art pet tracking technology for peace of mind.",
    "Luxury pet travel accommodations for the ultimate comfort.",
    "Door-to-door service in specialized pet-friendly vehicles.",
    "Flexible booking options and comprehensive insurance packages.",
    "Expert handling for special pet needs, including seniors and pets with anxiety.",
    "A commitment to eco-friendly travel and carbon-neutral flights.",
    "Exclusive member benefits and rewards for frequent travelers.",
  ];

  return (
    <Box bg="secondary.50" color="text" py="8">
      <MotionContainer
        transition={{ duration: 0.8, delay: 0.3 }}
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ x: 0, opacity: 1 }}
        maxW="container.lg"
      >
        <VStack spacing={4}>
          <MotionHeading
            transition={{ duration: 0.8, delay: 0.3 }}
            initial={{ opacity: 0, x: -120 }}
            whileInView={{ x: 0, opacity: 1 }}
            as="h2"
            size="xl"
            textAlign="center"
          >
            Why We're Different
          </MotionHeading>
          <MotionText
            transition={{ duration: 0.8, delay: 0.3 }}
            initial={{ opacity: 0, x: 80 }}
            whileInView={{ x: 0, opacity: 1 }}
            fontSize="lg"
            textAlign="center"
            maxW="container.md"
          >
            At AirPetTaxi, we go above and beyond to ensure a seamless and
            stress-free experience for you and your pets.
          </MotionText>
          <List spacing={3} textAlign="start" px={{ base: 4, md: 16 }} py={4}>
            {uniquePoints.map((point, index) => (
              <MotionListItem
                transition={{ duration: 0.8, delay: 0.2 }}
                initial={{ opacity: 0, x: 80 }}
                whileInView={{ x: 0, opacity: 1 }}
                key={index}
                fontSize="lg"
              >
                <ListIcon
                  as={MdCheckCircle}
                  color={theme.colors.primary[500]}
                />
                {point}
              </MotionListItem>
            ))}
          </List>
        </VStack>
      </MotionContainer>
    </Box>
  );
};

export default WhyWeAreDifferent;
