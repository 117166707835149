import React from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Text,
  Divider,
  Link,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faInstagram,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram as faInstagramBrand } from "@fortawesome/free-brands-svg-icons";
import { faFacebook as faFacebookBrand } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box bg="secondary.100" color="text" paddingY="6">
      <Container maxW="container.xl">
        <Flex
          justifyContent="space-around"
          flexDirection={{ base: "column", md: "row" }}
        >
          {/* Quick Links */}
          <Box display="flex" flexDirection="column">
            <Text fontWeight="bold" fontSize="lg" marginBottom="4">
              Quick Links:
            </Text>
            <Link
              color="text"
              fontWeight="bold"
              marginBottom="2"
              href="/"
              w="fit-content"
            >
              Home
            </Link>
            <Link
              color="text"
              fontWeight="bold"
              marginBottom="2"
              href="/about"
              w="fit-content"
            >
              About Us
            </Link>

            <Link
              color="text"
              fontWeight="bold"
              marginBottom="2"
              href="/process"
              w="fit-content"
            >
              The Process
            </Link>

            <Link
              color="text"
              fontWeight="bold"
              marginBottom="2"
              href="/contact"
              w="fit-content"
            >
              Contact
            </Link>

            <Divider color="text" my="3" />

            <Link marginBottom="2" href="/quote" w="min-content">
              <Button size="md" colorScheme="purple" color="white">
                Get a Quote
              </Button>
            </Link>

            <Link marginBottom="2" href="/join" w="min-content">
              <Button size="md" colorScheme="primary" color="white">
                Join Our Team
              </Button>
            </Link>
          </Box>

          <Box spacing="4">
            <Text fontSize="lg" fontWeight="bold" marginY="4">
              Contact Information:
            </Text>
            <Text marginBottom="2">
              <FontAwesomeIcon icon={faPhone} style={{ marginRight: "4px" }} />{" "}
              Phone: <Link href="tel:+17033006963">703-300-6963</Link>
            </Text>
            <Text marginBottom="2">
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ marginRight: "4px" }}
              />{" "}
              Email:{" "}
              <Link href="mailto:airpettaxillc@gmail.com">
                airpettaxillc@gmail.com
              </Link>
            </Text>
            <Text marginBottom="2">
              <FontAwesomeIcon
                icon={faInstagramBrand}
                style={{ marginRight: "4px" }}
              />{" "}
              Instagram:{" "}
              <Link href="https://www.instagram.com/airpettaxi" isExternal>
                airpettaxi
              </Link>
            </Text>
            <Text marginBottom="2">
              <FontAwesomeIcon
                icon={faFacebookBrand}
                style={{ marginRight: "4px" }}
              />{" "}
              Facebook:{" "}
              <Link href="https://www.facebook.com/airpettaxi" isExternal>
                airpettaxi
              </Link>
            </Text>
          </Box>
        </Flex>
        {/* Copyright Information */}
        <Text textAlign="center" marginTop="6">
          © 2023 AirPetTaxi. All rights reserved.
        </Text>
      </Container>
    </Box>
  );
};

export default Footer;
