import { Box, ChakraProvider, Flex } from "@chakra-ui/react";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import GetAQuote from "./components/GetAQuote";
import Home from "./components/Home/Home";
import Join from "./components/Join/Join";
import Navbar from "./components/Navbar/Navbar";
import ProcessSection from "./components/Process";
import "./index.css";
import theme from "./theme"; // Import your Chakra UI theme


// import Testimonials from "./components/Testimonials";

function App() {

  const initialOptions = {
    // flow: "checkout",
    clientId: "test",
    currency: "USD",
    intent: "capture",
  };

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Flex direction="column" minH="100vh">
          <Box as="header">
            <Navbar />
          </Box>
          <Box as="main" flex="1">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/process" element={<ProcessSection />} />
              <Route path="/join" element={<Join />} />
              <Route path="/quote" element={
                <PayPalScriptProvider options={initialOptions} >
                  <GetAQuote />
                </PayPalScriptProvider>} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </Box>
          <Box as="footer">
            <Footer />
          </Box>
        </Flex>
      </Router>
    </ChakraProvider>
  );
}

export default App;
