import React from "react";
import {
  Box,
  Container,
  Heading,
  VStack,
  Icon,
  Text,
  useTheme,
  Circle,
  StackDivider,
  Stack,
} from "@chakra-ui/react";
import { FaRegCheckCircle, FaPlane, FaSuitcase, FaHome } from "react-icons/fa"; // These are example icons
import { CSSTransition } from "react-transition-group";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

import servicesBg from "../assets/images/processBg.webp";

const ProcessSection = () => {
  const theme = useTheme();

  const MotionHeading = motion(Heading);
  const MotionText = motion(Text);
  const MotionVStack = motion(VStack);

  const steps = [
    {
      id: 1,
      icon: FaSuitcase,
      title: "Booking",
      description:
        "Start your pet’s journey with a simple booking process. Our consultants work with you to understand your pet’s needs and tailor the travel experience. We’ll help you select the best travel dates and prepare all necessary documents.",
    },
    {
      id: 2,
      icon: FaRegCheckCircle,
      title: "Preparation",
      description:
        "On the day of departure, our pet-friendly vehicle will arrive at your doorstep. Your pet will be greeted by our caring team, ensuring they are comfortable and secure before heading to the airport.",
    },
    {
      id: 3,
      icon: FaPlane,
      title: "Transportation",
      description:
        "During transit, your pet will be under the constant care of our flight nanny. We’ll ensure they are fed, hydrated, and given bathroom breaks. Your pet’s comfort and safety are our top priorities.",
    },
    {
      id: 4,
      icon: FaHome,
      title: "Arrival",
      description:
        "Upon arrival, you’ll be reunited with your pet without delay. We handle all the post-flight procedures to ensure a smooth transition back into your loving arms.",
    },
    // Add more steps as necessary
  ];

  return (
    <CSSTransition classNames="page" timeout={500}>
      <div className="page-entered">
        <Helmet>
          <title>Our Process - AirPetTaxi</title>
          <meta
            name="description"
            content="Explore our streamlined process for safe and comfortable pet transportation. Learn how AirPetTaxi ensures a stress-free travel experience for your pets from start to finish."
          />
          <meta
            name="keywords"
            content="pet transportation process, pet travel procedure, AirPetTaxi process, pet journey, pet transport steps"
          />
          {/* Other relevant SEO tags */}
          <script>
            {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '2899328293629083');
                fbq('track', 'PageView'); 
            `}
          </script>
          <noscript>
            {`
            <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=2899328293629083&ev=PageView&noscript=1" />
            `}
          </noscript>
        </Helmet>
        <Box
          overflowX="hidden"
          bgImage={servicesBg}
          bgPos="center"
          bgSize="cover"
          bgRepeat="no-repeat"
          position="relative"
          minHeight="max(calc(100vh - 60px), 700px)"
          display="flex"
          alignItems="start"
          justifyContent="center"
          textAlign="center"
        >
          {/* <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="whiteAlpha.200" // Semi-transparent black background
            zIndex="1" // Ensures overlay is behind the content
          /> */}
          <VStack
            position="relative"
            zIndex={3}
            color="primary.50"
            mb="8"
            borderRadius="xl"
            maxW="2xl"
            // bg="blackAlpha.500"
          >
            <MotionHeading
              transition={{ duration: 0.8, delay: 0.2 }}
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ x: 0, opacity: 1 }}
              as="h1"
              size="2xl"
              p={4}
              borderRadius="md"
              color="white"
            >
              Experience the Journey with Your Pet
            </MotionHeading>
            <MotionText
              transition={{ duration: 0.8, delay: 0.2 }}
              initial={{ opacity: 0, x: 90 }}
              whileInView={{ x: 0, opacity: 1 }}
              fontSize="lg"
              p={2}
              borderRadius="md"
              color="secondary.100"
            >
              From the first cuddle to the final purr, we make every step of
              your pet’s travel pleasant and safe.
            </MotionText>
          </VStack>
        </Box>
        <Box bg="background" py={10} overflowX="hidden" color="text">
          <Container maxW="container.xl" py={10}>
            <VStack spacing={8} align="stretch">
              <MotionHeading
                transition={{ duration: 0.8, delay: 0.2 }}
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ x: 0, opacity: 1 }}
                as="h2"
                size="2xl"
                textAlign="center"
              >
                How It Works
              </MotionHeading>
              <MotionText
                transition={{ duration: 0.8, delay: 0.2 }}
                initial={{ opacity: 0, x: 75 }}
                whileInView={{ x: 0, opacity: 1 }}
                fontSize="lg"
                textAlign="center"
                s
              >
                We ensure a seamless and stress-free experience for your pet.
                Here’s our step-by-step process:
              </MotionText>
              <Container maxW="container.md">
                <Stack
                  direction="column"
                  spacing={{ base: 8, md: 4 }}
                  divider={
                    <StackDivider borderColor={theme.colors.gray[200]} />
                  }
                  orientation="vertical"
                  justifyContent="space-between"
                >
                  {steps.map((step, index) => (
                    <MotionVStack
                      transition={{ duration: 0.8, delay: 0.3 }}
                      initial={{ opacity: 0, x: index % 2 === 0 ? -150 : 150 }}
                      whileInView={{ x: 0, opacity: 1 }}
                      key={step.id}
                      spacing={4}
                      align={index % 2 === 0 ? "start" : "end"}
                    >
                      <Circle
                        size="50px"
                        bg={theme.colors.primary[500]}
                        color="white"
                        align={index % 2 === 0 ? "start" : "end"}
                      >
                        <Icon as={step.icon} boxSize="24px" />
                      </Circle>
                      <VStack
                        spacing={2}
                        maxW="sm"
                        align={index % 2 === 0 ? "start" : "end"}
                      >
                        <Heading as="h3" size="md">
                          {step.title}
                        </Heading>
                        <Text align={index % 2 === 0 ? "start" : "end"}>
                          {step.description}
                        </Text>
                      </VStack>
                    </MotionVStack>
                  ))}
                </Stack>
              </Container>
            </VStack>
          </Container>
        </Box>
      </div>
    </CSSTransition>
  );
};

export default ProcessSection;
